<template>
    <div class="Message xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>我的收藏</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit">
                        <i class="iconfont">&#xe62c;</i><span>我的<em>收藏</em></span>
                        <ul class="tab-tit">
                            <li v-for="(title, index) in tabTitle" @click="cur = index, tabClick(title)"
                                :class="{ active: index == cur }">{{ title.name }}</li>
                        </ul>
                        <el-link type="danger" :underline="false" class="el-icon-delete" @click="deleteAll"></el-link>
                    </div>
                    <!--    内容    -->
                    <div class="center-content">
                        <el-table :data="tableData" style="width: 100%" :show-header="false"
                            @selection-change="handleSelectionChange" @row-click="rowClick">
                            <el-table-column type="selection" width="45">
                            </el-table-column>
                            <el-table-column prop="entityName" label="">
                            </el-table-column>
                            <el-table-column prop="typeName" label="">
                            </el-table-column>
                            <el-table-column prop="collectionDate" label="" align="right">
                                <template slot-scope="scope">{{ scope.row.collectionDate | timefilters }}</template>
                            </el-table-column>
                        </el-table>
                        <el-pagination @current-change="ChangePage" :page-size="15" :current-page="PageIndex"
                            :total="CollectTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
</div>
</template>

<script>
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { converName } from "../../utils/extend";
import { Collection } from "../../components/collection/Collection";
import { clickName } from "../../utils/relation";
export default {
    name: "Collect",
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        var collection = new Collection(this.TokenClient, this.Services.Statics)
        return {
            labelPosition: 'right',
            tabTitle: [],
            tableData: [{ collectionDate: "" }],
            false: false,
            cur: 0, //默认选中第一个tab
            collectionDomain: collection,
            results: [],
            selectData: [],
            PageIndex: 1,
            PageTotal: 1,
            CollectTotal: 0,
            str: this.$store.state.localData.typeList.toString()+',Video',
            type: '',
            list: []
        };
    },
    mounted() {
        this.type = this.str
        this.getMenu()
    },
    methods: {
        getMenu() {
            var _this = this;
            _this.collectionDomain.Collections(_this.PageIndex, _this.type,
                function (data) {
                    var results = data.data.results
                    results.types.forEach(element => {
                        _this.tabTitle.push({
                            type: element,
                            name: converName(element)
                        })

                    });
                    _this.type = _this.tabTitle[0].type
                    _this.init()
                }, function (err) {
                    console.log(err)
                });
        },
        init() {
            var _this = this;
            _this.collectionDomain.Collections(_this.PageIndex, _this.type,
                function (data) {
                    _this.tableData = data.data.results.collections
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageTotal = data.data.pageCount;
                    _this.CollectTotal = data.data.dataTotal;
                }, function (err) {
                    console.log(err)
                });
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.init();
        },
        tabClick: function (item) {
            if (item.name == '全部') {
                this.type = this.str
            } else {
                this.type = this.tabTitle[this.cur].type
            }

            this.init()
            // this.tableData = this.results.filter(x => x.type == item.type);
        },
        deleteAll: function () {
            var _this = this;
            if (this.selectData == null || Object.keys(this.selectData).length == 0) {
                _this.$message.error('请选择要删除的项！');
                return false;
            }
            this.$confirm('此操作将删除选中的收藏, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var idarr = _this.selectData.map(x => x.collectionId)
                _this.collectionDomain.Delete(idarr, function (data) {
                    _this.$message({
                        center: true,
                        type: 'success',
                        message: '删除成功!'
                    });
                    _this.init();
                },
                    function (error) {
                        console.log(error);
                    })

            }).catch(() => {
                this.$message({
                    center: true,
                    type: 'info',
                    message: '已取消删除'
                })
            })
        },
        handleSelectionChange: function (val) {
            this.selectData = val
        },
        rowClick: function (row, even, column) {
            if (row.type=="Video") {
                clickName(row.type, row.entityId)
            } else {
                clickName(row.type, row.entityKey)
            }
            
        }
    },


}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    min-height: 740px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right-tit .el-icon-delete {
    float: right;
    font-size: 20px;
    margin-top: 10px;
}

.tab-tit {
    margin-left: 40px;
    display: inline-block;
}

.tab-tit li {
    font-size: 16px;
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
    font-weight: bold;
}

.tab-tit li.active {
    color: #1fb7a6;
}

.center-content {
    padding: 0 20px;
}
</style>
<style>
.center-content .el-table .cell {
    cursor: pointer !important;
}

.el-table::before {
    height: 0;
}

.el-table td,
.el-table th.is-leaf {
    border-bottom: 1px dashed #EBEEF5;
}
</style>
